import { FC } from 'lib/component-utils'
import { useUserContext } from 'lib/context'

const MallAnnouncement: FC = () => {
  const ctx = useUserContext()

  return (
    <div className="relative bg-yellow-100">
      <div className="px-3 py-3 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="pr-16 sm:text-center sm:px-16">
          <p className="font-medium text-yellow-800">
            <span>{ctx.currentMall.notification}</span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default MallAnnouncement
