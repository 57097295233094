import { DateTimeFormat as PolyfillDateTimeFormat } from '@formatjs/intl-datetimeformat'

const LOCALES_THAT_ARE_POLYFILLED = ['uz']

const OriginalDateTimeFormat = Intl.DateTimeFormat

const DynamicDateTimeFormat = new Proxy(PolyfillDateTimeFormat, {
  construct(target, args) {
    if (LOCALES_THAT_ARE_POLYFILLED.includes(args[0])) {
      return new target(...args)
    } else {
      return OriginalDateTimeFormat(...args)
    }
  },
  get(target, prop) {
    if (prop === 'supportedLocalesOf') {
      return (
        value: string | string[],
        options?: Pick<Intl.DateTimeFormatOptions, 'localeMatcher'>
      ) => {
        if (typeof value === 'string') {
          if (LOCALES_THAT_ARE_POLYFILLED.includes(value)) {
            return target.supportedLocalesOf(value, options)
          } else {
            return OriginalDateTimeFormat.supportedLocalesOf(value, options)
          }
        } else {
          return value.map((locale) => {
            if (LOCALES_THAT_ARE_POLYFILLED.includes(locale)) {
              return target.supportedLocalesOf(locale, options)[0]
            } else {
              return OriginalDateTimeFormat.supportedLocalesOf(locale, options)[0]
            }
          })
        }
      }
    } else {
      // @ts-ignore
      return target[prop]
    }
  },
})

// also keep in mind to monkey-patch toLocaleString, toLocaleTimeString, toLocaleDateString
Object.defineProperty(Intl, 'DateTimeFormat', {
  value: DynamicDateTimeFormat,
  configurable: true,
  enumerable: false,
  writable: true,
})

const _promise = Promise.all([
  import('@formatjs/intl-datetimeformat/locale-data/uz'), // need to be statically defined for bundlers
])
