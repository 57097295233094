import * as SentryIntegration from '@sentry/nextjs'
import { useUserContext } from 'lib/context'

const Sentry = () => {
  const { user } = useUserContext()

  SentryIntegration.setUser({
    username: user?.fullName ?? undefined,
    email: user?.email ?? undefined,
    id: user?.id.toString() ?? undefined,
    activeUntil: user?.activeUntil ?? undefined,
    roles: user?.roles ?? undefined,
  })
  return null
}

export default Sentry
