import { motion } from 'framer-motion'
import { c, FC } from 'lib/component-utils'
import Router from 'next/router'
import { useCallback, useEffect, useRef, useState } from 'react'

const PageLoadProgressBar: FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [progress, setProgress] = useState(0)
  const [timeouts, setTimeouts] = useState<NodeJS.Timeout[]>([])
  const currentTimeouts = useRef<NodeJS.Timeout[]>([])

  currentTimeouts.current = timeouts

  const startLoading = useCallback(() => {
    currentTimeouts.current.forEach(clearTimeout)
    setIsLoading(true)
    setProgress(50)
    const timeout1 = setTimeout(() => setProgress(92), 500)
    const timeout2 = setTimeout(() => setProgress(95), 1500)
    const timeout3 = setTimeout(() => setProgress(98), 3500)
    const timeout4 = setTimeout(() => setProgress(99), 7500)
    setTimeouts([timeout1, timeout2, timeout3, timeout4])
  }, [setIsLoading, setProgress, currentTimeouts])

  const endLoading = useCallback(() => {
    currentTimeouts.current.forEach(clearTimeout)
    setProgress(100)
    const timeout1 = setTimeout(() => setIsLoading(false), 200)
    const timeout2 = setTimeout(() => setProgress(0), 750)
    setTimeouts([timeout1, timeout2])
  }, [setIsLoading, setProgress, currentTimeouts])

  useEffect(() => {
    Router.events.on('routeChangeStart', startLoading)
    Router.events.on('routeChangeComplete', endLoading)
    Router.events.on('routeChangeError', endLoading)

    return () => {
      Router.events.off('routeChangeStart', startLoading)
      Router.events.off('routeChangeComplete', endLoading)
      Router.events.off('routeChangeError', endLoading)
    }
  }, [startLoading, endLoading])

  return (
    <motion.div
      style={{ width: `${progress}%` }}
      className={c`fixed left-0 origin-left top-0 z-[1002] h-[2px] duration-500 ease-in-out bg-primary-500 ${isLoading} opacity-100 | opacity-0`}
    />
  )
}

export default PageLoadProgressBar
