import { NavItem, SubnavItem, navbar } from 'configs/navbar'
import { NextRouter } from 'next/router'

export const navItemIsActive = (nav: NavItem | SubnavItem, router: NextRouter): boolean => {
  return nav.active.some((p) => router.asPath.includes(p.slice(0, p.length - 1)))
}

export const findActiveNavItem = (router: NextRouter) => {
  return navbar
    .flatMap((item) => item.items)
    .find((item) => item.active.some((p) => router.asPath.includes(p.slice(0, p.length - 1))))
}
