import { FC } from 'lib/component-utils'
import { useUserContext } from 'lib/context'
import { useIntl } from 'lib/intl-utils'
import { Router } from 'next/router'
import { useCallback, useEffect } from 'react'

const JivoEvents: FC = () => {
  const { user } = useUserContext()
  const { format } = useIntl()

  useEffect(() => {
    const it = window as any

    it.jivo_onLoadCallback = function jivo_onLoadCallback() {
      it.jivo_api.setContactInfo({ name: user.fullName, email: user.email, phone: user.phone })

      const customData = [
        {
          content: 'Перейти в админку',
          link: `https://${window.location.host}/admin/app/user/${user.id}/show`,
        },
        {
          title: 'Подписка активна до',
          content: format.date(user.activeUntil) ?? 'закончилась',
        },
        {
          title: 'Доступны маркетплейсы',
          content: user.activeMalls.join(', '),
        },
      ]

      it.jivo_api.setCustomData(customData)
    }
  }, [])

  const sendPageTitle = useCallback(() => {
    const it = window as any
    const title = document.title
    const url = window.location.href
    if (!it.jivo_api) return

    it.jivo_api.sendPageTitle(title, true, url)
  }, [])

  useEffect(() => {
    Router.events.on('routeChangeComplete', sendPageTitle)

    return () => {
      Router.events.off('routeChangeComplete', sendPageTitle)
    }
  }, [])

  return null
}

export default JivoEvents
