import { subnavPaneContentsAtom } from 'components/sidebar/lib/atoms'
import { navItemIsActive } from 'components/sidebar/lib/navItems'
import { AnimatePresence } from 'framer-motion'
import { motion } from 'framer-motion'
import { useAtomValue } from 'jotai'
import { FC, c } from 'lib/component-utils'
import { useUserContext } from 'lib/context'
import { usePrevious } from 'lib/hooks'
import { useIntl } from 'lib/intl-utils'
import Link from 'components/base/Link'
import { useRouter } from 'next/router'

type Props = {}

const SubnavPane: FC<Props> = ({ className }) => {
  const ctx = useUserContext()
  const { t } = useIntl()
  const subnavItems = useAtomValue(subnavPaneContentsAtom)
  const previousSubnavItems = usePrevious(subnavItems)
  const router = useRouter()

  return (
    <AnimatePresence>
      {subnavItems && subnavItems.length > 0 && (
        <motion.div
          className={`w-56 h-screen bg-white rounded-r-xl ring-1 ring-gray-200 ${className}`}
          initial={{ opacity: 0, translateX: -48 }}
          animate={{ opacity: 1, translateX: 0 }}
          exit={{ opacity: 0, translateX: -48 }}
          transition={{ ease: 'easeInOut', duration: 0.25 }}
        >
          <AnimatePresence>
            <motion.div
              key={subnavItems.map((item) => item.name).join()}
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: { ease: 'easeOut', delay: previousSubnavItems ? 0.3 : 0 },
              }}
              exit={{ opacity: 0, transition: { ease: 'easeIn' } }}
              transition={{ duration: 0.1 }}
              className="flex flex-col py-4"
            >
              {subnavItems.map((item) => (
                <Link
                  key={item.name}
                  href={item.route + (item.addMall ? `${ctx.currentMall.code}/` : '')}
                  className={c`group px-2 py-0.5 font-medium text-gray-700`}
                >
                  <div
                    className={c`py-2.5 px-4 rounded-lg ${navItemIsActive(
                      item,
                      router
                    )} bg-primary-100 group-hover:bg-primary-200 | group-hover:bg-gray-100`}
                  >
                    {t(item.name)}
                  </div>
                </Link>
              ))}
            </motion.div>
          </AnimatePresence>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default SubnavPane
