import useTranslation from 'next-translate/useTranslation'
import { useMercureEvent } from 'lib/events'
import { ExportMercureMessage } from 'lib/dto'
import { useNotification } from './NotificationArea'

const Notifications = () => {
  const { t } = useTranslation('common')
  const showNotification = useNotification()

  useMercureEvent<ExportMercureMessage>('export.finished', (_, data) => {
    showNotification({
      type: 'success',
      title: t`success`,
      message: t`export_finished`,
      primaryLink: {
        title: t`download`,
        props: { download: true, href: `/inner/export/report/${data.reportId}/download/` },
      },
    })
  })
  useMercureEvent<ExportMercureMessage>('export.error', (_, data) => {
    showNotification({ type: 'error', title: t`error`, message: t`export_failed` })
  })
  return null
}

export default Notifications
