import { useUserContext } from 'lib/context'
import { useRouter } from 'next/dist/client/router'
import { useEffect } from 'react'

const FacebookMetrics = () => {
  const { theme } = useUserContext()
  const router = useRouter()
  const { id } = theme.facebookMetrics!

  useEffect(() => {
    ;(function (f: any, b, e, v, n?: any, t?: any, s?: any) {
      if (f.fbq) return
      n = f.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
      }
      if (!f._fbq) f._fbq = n
      n.push = n
      n.loaded = !0
      n.version = '2.0'
      n.queue = []
      t = b.createElement(e)
      t.async = !0
      t.src = v
      s = b.getElementsByTagName(e)[0]
      s.parentNode.insertBefore(t, s)
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')

    if (window.location.pathname !== '/') return

    const fbq = (window as any).fbq

    fbq('init', id.toString())
    fbq('track', 'PageView')

    const handler = (url: string, { shallow }: { shallow: boolean }) => {
      if (url !== '/signup/') return

      fbq('track', 'PageView')
    }

    router.events.on('routeChangeComplete', handler)

    return () => router.events.off('routeChangeComplete', handler)
  }, [id, router.events])

  return (
    <noscript>
      <img
        height="1"
        width="1"
        style={{ display: 'none' }}
        src={`https://www.facebook.com/tr?id=${id}&ev=PageView&noscript=1`}
      />
    </noscript>
  )
}

export default FacebookMetrics
