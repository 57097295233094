import Modal from 'components/base/Modal'
import { useAtom } from 'jotai'
import { useSetAtom } from 'jotai'
import { currentModalAtom } from 'lib/atoms'
import { FC } from 'lib/component-utils'
import { useEffectOnUpdate } from 'lib/hooks'

export const useModal = <T extends unknown[]>(
  contents: (...args: T) => JSX.Element,
  dependencies: unknown[] = []
) => {
  const [{ shown, wasCreatedWithArgs }, set] = useAtom(currentModalAtom)

  useEffectOnUpdate(() => {
    if (!shown) {
      return
    }

    if (wasCreatedWithArgs) {
      set((state) => {
        state.contents = null
        state.wasCreatedWithArgs = false
        state.shown = false
      })
    }

    set((state) => {
      // @ts-expect-error
      state.contents = contents()
    })
  }, dependencies)

  return (...args: T) =>
    set((state) => {
      state.contents = contents(...args)
      if (
        args.length > 0 &&
        !(typeof args[0] === 'object' && args[0] !== null && 'bubbles' in args[0])
      ) {
        state.wasCreatedWithArgs = true
      }
      state.shown = true
    })
}

export const useHideModal = () => {
  const set = useSetAtom(currentModalAtom)

  return () =>
    set((state) => {
      state.shown = false
      state.contents = null
      state.wasCreatedWithArgs = false
    })
}

const ModalDispatcher: FC = () => {
  const [{ shown, contents }, set] = useAtom(currentModalAtom)

  return (
    <Modal
      open={shown}
      setOpen={(value) =>
        set((state) => {
          state.shown = value
        })
      }
    >
      {contents}
    </Modal>
  )
}

export default ModalDispatcher
