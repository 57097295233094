import { useUserContext } from 'lib/context'
import { useEffect } from 'react'
import PubSub from 'pubsub-js'
import { MercureMessage } from 'lib/dto'
import { FC } from 'lib/component-utils'

const Events: FC = () => {
  const { user } = useUserContext()

  useEffect(() => {
    const eventSource = new EventSource(
      encodeURI(
        `${process.env.NEXT_PUBLIC_MERCURE_USER_URL}?topic=${process.env.NEXT_PUBLIC_MERCURE_TOPIC_PREFIX}/notifications/user/${user.id}`
      ),
      { withCredentials: true }
    )
    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data) as MercureMessage
      PubSub.publish(data.event, data.data)
    }

    return () => eventSource.close()
  }, [user.id])
  return null
}

export default Events
