import { c, FC } from 'lib/component-utils'
import { forwardRef } from 'react'
import Tooltip from './Tooltip'
import { QuestionMarkCircleIcon } from '@heroicons/react/outline'

const Card: FC = ({ className, ...rest }, ref) => {
  return (
    <div
      className={c`bg-white rounded-lg shadow-sm ring-1 ring-gray-200 overflow-hidden ${className}`}
      ref={ref}
      {...rest}
    />
  )
}

export default forwardRef(Card as any) as typeof Card

export const CardHeader: FC<{ tooltip?: string }> = forwardRef(
  ({ className, tooltip, children, ...rest }, ref) => {
    return (
      <div className={c`flex items-center ${className}`} ref={ref} {...rest}>
        <h3 className={c`text-gray-600 text-sm`}>{children}</h3>
        {tooltip && (
          <Tooltip sizing="text" content={tooltip}>
            <QuestionMarkCircleIcon className="w-4 h-4 ml-1 text-gray-400" aria-hidden="true" />
          </Tooltip>
        )}
      </div>
    )
  }
) as FC<{ tooltip?: string }>
CardHeader.displayName = 'CardHeader'
