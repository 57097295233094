import { Key } from 'react'

export type Mutable<T> = {
  -readonly [K in keyof T]: Mutable<T[K]>
}

export type Displayable = JSX.Element | string | number | null | undefined

export type ReactKey = string | number | null | undefined

export type KeysOfType<T, TProp> = keyof Pick<
  T,
  { [K in keyof T]: T[K] extends TProp ? K : never }[keyof T]
>

export type Extractor<T, TExtracted> =
  | KeysOfType<T, TExtracted>
  | ((value: T) => TExtracted)
  | undefined

export type KeyExtractor<T> = Extractor<T, ReactKey>

export type DisplayExtractor<T> = Extractor<T, Displayable>

export const extract = <T, TExtracted>(value: T, extractor: Extractor<T, TExtracted>) =>
  (typeof extractor === 'function'
    ? extractor(value)
    : typeof extractor !== 'undefined'
    ? value[extractor]
    : value) as TExtracted

export const identity = <T>(arg: T) => arg

export type EventCategory<C extends string, T extends string> = `${C}${`.${T}` | ''}`
