import { SubnavItem } from 'configs/navbar'
import { atom } from 'jotai'
import { atomWithStorage } from 'lib/local-storage'

export const sidebarSizeSettingAtom = atomWithStorage('sidebarSize', 'auto')
export const windowDimensionsAtom = atom([0, 0] as [number, number])
export const sidebarIsCollapsibleAtom = atom((get) => {
  const [width, height] = get(windowDimensionsAtom)

  return !(width < 1394 || height < 750)
})
export const sidebarIsLargeAtom = atom((get) => {
  const sidebarSize = get(sidebarSizeSettingAtom)
  const isCollapsible = get(sidebarIsCollapsibleAtom)

  return isCollapsible && sidebarSize !== 'compact'
})

export const subnavPaneContentsAtom = atom<SubnavItem[] | null>(null)
