import { MailIcon, PhoneIcon } from '@heroicons/react/outline'
import { c, FC } from 'lib/component-utils'
import { useUserContext } from 'lib/context'
import { useIntl } from 'lib/intl-utils'
import { useEffect, useState } from 'react'
import { TelegramIcon, WhatsAppIcon, YouTubeIcon } from 'components/icons'
import { useRouter } from 'next/router'
import Link from 'components/base/Link'

const links = [
  {
    name: 'Telegram',
    prefix: 'https://t.me/',
    href: (c: any) => c.telegram,
    icon: TelegramIcon,
  },
  // {
  //   name: 'Instagram',
  //   prefix: 'https://instagram.com/',
  //   href: (c: any) => c.instagram,
  //   icon: InstagramIcon,
  // },
  { name: 'WhatsApp', prefix: 'https://wa.me/', href: (c: any) => c.whatsapp, icon: WhatsAppIcon },
  {
    name: 'Email',
    prefix: 'mailto:',
    href: (c: any) => c.mail,
    icon: MailIcon,
  },
  {
    name: 'Phone',
    prefix: 'tel:',
    href: (c: any) => c.phone,
    icon: PhoneIcon,
  },
  {
    name: 'YouTube',
    prefix: 'https://youtube.com/',
    href: (c: any) => c.youtube,
    icon: YouTubeIcon,
  },
]

const SellematicsFooter: FC = ({ className }) => {
  const { t, lang } = useIntl()
  const { theme } = useUserContext()
  const router = useRouter()
  const [hide, setHide] = useState(false)

  useEffect(() => {
    const iframe = document.querySelector('main > div > iframe')

    if (iframe !== null) {
      setHide(true)
    } else {
      setHide(false)
    }
  }, [router.asPath])

  const currentYear = new Date().getFullYear()
  const lifespan =
    theme.releaseDate !== currentYear ? theme.releaseDate + '–' + currentYear : theme.releaseDate

  if (hide) return null

  return (
    <footer className="bg-white border-t border-gray-200" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:py-16 lg:px-8">
        <div className="lg:flex lg:gap-8">
          <div className="space-y-6 xl:col-span-1">
            <img src={theme.logoDark} className={c`w-44`} alt={`${theme.title} logo`} />
            <div className="text-base text-gray-700">
              Сервис аналитики и исследований e-commerce
            </div>
          </div>
          <div className="grid grid-cols-2 grid-rows-2 mt-12 grow gap-y-8 sm:gap-x-16 sm:grid-flow-col sm:grid-cols-none sm:grid-rows-1 lg:justify-items-end xl:col-span-2 lg:mt-0">
            <div>
              <div className="flex flex-col space-y-4 text-base text-gray-700">
                <h3 className="font-medium">Компания</h3>
                <Link href="/articles/" className="hover:text-gray-900">
                  Блог
                </Link>
                <Link href="/about/" className="hover:text-gray-900">
                  О нас
                </Link>
              </div>
            </div>
            <div>
              <div className="space-y-4">
                <div>
                  <a href="tel:+74954141011" className="text-xl text-gray-700 hover:text-gray-900">
                    +7 495 414-10-11
                  </a>
                </div>
                <div>
                  <a
                    href="mailto:info@sellematics.com"
                    className="text-base text-gray-700 hover:text-gray-900"
                  >
                    info@sellematics.com
                  </a>
                </div>
                <div className="flex flex-col mt-2">
                  <span className="text-sm text-gray-500">WhatsApp</span>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`https://wa.me/${theme.contactInformation.whatsapp}`}
                    className="text-base text-gray-700 hover:text-gray-900"
                  >
                    +7 989 971-68-47
                  </a>
                </div>
              </div>
            </div>
            <div>
              <div className="space-y-3 text-base text-gray-700">
                <div className="font-medium">ООО &quot;СЕЛЛЕМАТИКС&quot;</div>
                <div className="grid grid-flow-col grid-rows-2 gap-y-3 gap-x-8">
                  <div className="flex flex-col">
                    <span className="text-sm text-gray-500">ИНН</span>
                    <span>7727492799</span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-sm text-gray-500">КПП</span>
                    <span>772701001</span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-sm text-gray-500">ОГРН</span>
                    <span>1227700252878</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-baseline pt-8 mt-12 space-y-4 border-t border-gray-200 lg:flex-row">
          <div>
            {lang === 'en' && (
              <p className="text-base text-gray-400">
                &copy; {lifespan} {theme.title}. All rights reserved.
              </p>
            )}
            {lang === 'ru' && (
              <p className="text-base text-gray-400">
                &copy; {theme.title}, {lifespan}. Все права защищены.
              </p>
            )}
          </div>
          <div className="flex flex-col space-y-2 text-gray-400 lg:space-x-6 lg:space-y-0 lg:flex-row lg:ml-auto">
            <a
              className="text-sm hover:text-gray-500"
              href="/doc/sellematics/ru/privacy_policy.pdf"
            >
              {t`footer.privacy_policy`}
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default SellematicsFooter
