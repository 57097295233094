import { c, FC } from 'lib/component-utils'

const ItemGroup: FC = ({ children, className }) => {
  return (
    <div
      className={c`large-sidebar:items-start large-sidebar:w-full large-sidebar:space-y-0.5 large-sidebar:px-2 large-sidebar:py-0 flex flex-col ${className}`}
    >
      {children}
    </div>
  )
}

export default ItemGroup
