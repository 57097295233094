import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { c, FC } from 'lib/component-utils'
import Button from 'components/base/Button'
import { useHideModal } from 'components/app/ModalDispatcher'

type Props = {
  text: string
}

const ModalWithHeader: FC<Props> = ({ text, className, children }) => {
  const hideModal = useHideModal()

  return (
    <>
      <div
        className={c`sticky top-0 z-10 rounded-t-lg px-8 flex items-center bg-gray-100 place-content-between ${
          text.length > 0
        } py-3 | py-1.5 ${className}`}
      >
        <Dialog.Title as="h3" className="text-xl font-medium">
          {text}
        </Dialog.Title>
        <Button
          theme="gray"
          onClick={() => hideModal()}
          className="px-2 -mr-2 focus:!ring-0 focus:!ring-offset-0"
        >
          <XIcon className="w-5 h-5" />
        </Button>
      </div>
      {children}
    </>
  )
}

export default ModalWithHeader
