import { BrowserInfo } from 'detect-browser'
import { FC } from 'lib/component-utils'
import { storage } from 'lib/local-storage'
import { useEffect } from 'react'
import { useAtomValue } from 'jotai'
import { browserInfoAtom } from 'lib/atoms'
import ModalWithHeader from 'components/base/ModalWithHeader'
import { useModal } from './ModalDispatcher'
import Button from 'components/base/Button'
import { useIntl } from 'lib/intl-utils'
import { useUserContext } from 'lib/context'

const chromeVersion = 85
const safariVersion = 14.1
const browserVersions: Partial<
  Record<BrowserInfo['name'] | 'bot' | 'node' | 'react-native', number>
> = {
  chrome: chromeVersion,
  android: chromeVersion,
  'chromium-webview': chromeVersion,
  'edge-chromium': chromeVersion,
  edge: chromeVersion,
  'edge-ios': chromeVersion,
  crios: chromeVersion,
  safari: safariVersion,
  ios: safariVersion,
  'ios-webview': safariVersion,
  firefox: 86,
  opera: 71,
  samsung: 14,
  yandexbrowser: 20.9,
  miui: 12,
}

const BrowserCheck: FC = () => {
  const ctx = useUserContext()
  const showWarningModal = useModal(() => <WarningModal />)
  const browser = useAtomValue(browserInfoAtom)

  let showWarning = false
  if (
    ctx.isAuthorized &&
    (!browser?.name ||
      !(browser.name in browserVersions) ||
      browserVersions[browser.name]! > Number.parseFloat(browser.version!))
  ) {
    showWarning = true
  }

  useEffect(() => {
    if (showWarning && !storage.get('oldBrowserWarningShown')) {
      showWarningModal()
      storage.set('oldBrowserWarningShown', true)
    }
  }, [showWarning])

  return null
}

const WarningModal: FC = () => {
  const { t, lang } = useIntl()
  const browser = useAtomValue(browserInfoAtom)?.name!

  return (
    <ModalWithHeader text={t`browser_check.browser_outdated`}>
      <div className="px-8 pt-6 pb-8 prose">
        <h4>{t`browser_check.what_happened`}</h4>
        <p>{t`browser_check.what_happened_answer`}</p>
        <h4>{t`browser_check.what_does_it_mean`}</h4>
        <p>{t`browser_check.what_does_it_mean_answer`}</p>
        <h4>{t`browser_check.what_can_i_do`}</h4>
        <p>{t`browser_check.what_can_i_do_answer`}</p>
        <p className="flex flex-col space-y-3 [&>a]:w-max">
          {browser === 'chrome' && (
            <Button
              as="a"
              theme="primary"
              href="https://support.google.com/chrome/answer/95414"
              target="_blank"
              rel="noreferrer"
            >
              {t`browser_check.learn_how_to_update`} Chrome
            </Button>
          )}
          {browser === 'firefox' && (
            <Button
              as="a"
              theme="primary"
              href={
                lang === 'ru'
                  ? 'https://support.mozilla.org/ru/kb/obnovlenie-firefox-do-poslednego-vypuska'
                  : 'https://support.mozilla.org/en-US/kb/update-firefox-latest-release'
              }
              target="_blank"
              rel="noreferrer"
            >
              {t`browser_check.learn_how_to_update`} Firefox
            </Button>
          )}
          {browser === 'safari' && (
            <Button
              as="a"
              theme="primary"
              href="https://support.apple.com/HT204416"
              target="_blank"
              rel="noreferrer"
            >
              {t`browser_check.learn_how_to_update`} Safari
            </Button>
          )}
          {browser === 'yandexbrowser' && (
            <Button
              as="a"
              theme="primary"
              href="https://browser.yandex.ru/help/about/install.html"
              target="_blank"
              rel="noreferrer"
            >
              {t`browser_check.learn_how_to_update`} Yandex Browser
            </Button>
          )}
          {browser === 'edge' && (
            <Button
              as="a"
              theme="primary"
              href={
                lang === 'ru'
                  ? 'https://support.microsoft.com/ru-ru/topic/%D0%BF%D0%B0%D1%80%D0%B0%D0%BC%D0%B5%D1%82%D1%80%D1%8B-%D0%BE%D0%B1%D0%BD%D0%BE%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F-microsoft-edge-af8aaca2-1b69-4870-94fe-18822dbb7ef1'
                  : 'https://support.microsoft.com/en-us/topic/microsoft-edge-update-settings-af8aaca2-1b69-4870-94fe-18822dbb7ef1'
              }
              target="_blank"
              rel="noreferrer"
            >
              {t`browser_check.learn_how_to_update`} Edge
            </Button>
          )}
          {browser !== 'chrome' && (
            <Button
              as="a"
              theme="primary"
              href="https://www.google.com/chrome/"
              target="_blank"
              rel="noreferrer"
            >
              {t`browser_check.download_chrome`}
            </Button>
          )}
        </p>
      </div>
    </ModalWithHeader>
  )
}

export default BrowserCheck
