import { FC, c } from 'lib/component-utils'
import { linkEvents } from 'lib/linkEvents'
import NextLink from 'next/link'
import { forwardRef } from 'react'

const Link: FC<{ href: string; innerClassName?: string }, typeof NextLink> = (
  { href, children, className, innerClassName, onClick, ...rest },
  ref
) => {
  const isInset = className?.includes('inset-0') ?? false

  return (
    <NextLink ref={ref} href={href} className={className} {...rest}>
      <span
        className={c`${isInset} absolute inset-0 ${innerClassName}`}
        onClick={(e) => {
          onClick?.(e as any)
          const shouldContinue = linkEvents.registerEvent(href)
          if (!shouldContinue) {
            e.preventDefault()
            e.stopPropagation()
          }
        }}
      >
        {children}
      </span>
    </NextLink>
  )
}

export default forwardRef(Link as any) as typeof Link
