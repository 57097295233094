import { nanoid } from 'ai'

type LinkEventHandler = (href: string) => boolean

class LinkEvents {
  private handlers = new Map<string, LinkEventHandler>()

  onNavigation(handler: LinkEventHandler) {
    const id = nanoid()
    this.handlers.set(id, handler)
    return id
  }

  offNavigation(id: string) {
    this.handlers.delete(id)
  }

  registerEvent(href: string) {
    return [...this.handlers.values()].map((handler) => handler(href)).every((x) => x)
  }
}

export const linkEvents = new LinkEvents()

export type NavigationEventData =
  | { type: 'firstTime'; href: string; state: object; addToState: (data: object) => void }
  | { type: 'popState'; href: string; state: object }
export type NavigationEventHandler = (data: NavigationEventData) => boolean

class NavigationEvents {
  private handlers = new Map<string, NavigationEventHandler>()

  get size() {
    return this.handlers.size
  }

  onNavigation(handler: NavigationEventHandler) {
    const id = nanoid()
    this.handlers.set(id, handler)
    return id
  }

  offNavigation(id: string) {
    this.handlers.delete(id)
  }

  registerEvent(data: NavigationEventData) {
    return [...this.handlers.values()].map((handler) => handler(data)).every((x) => x)
  }
}

export const navigationEvents = new NavigationEvents()
