import { useUserContext } from 'lib/context'
import { useRouter } from 'next/dist/client/router'
import { useEffect } from 'react'

const YandexMetrica = () => {
  const { theme } = useUserContext()
  const router = useRouter()
  const { id, options } = theme.yandexMetrica!

  useEffect(() => {
    ;(function (m: any, e: any, t: any, r: any, i: any, k: any = undefined, a: any = undefined) {
      m[i] =
        m[i] ||
        function () {
          ;(m[i].a = m[i].a || []).push(arguments)
        }
      m[i].l = Date.now()
      ;(k = e.createElement(t)),
        (a = e.getElementsByTagName(t)[0]),
        (k.async = 1),
        (k.src = r),
        a.parentNode.insertBefore(k, a)
    })(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym')

    const ym = (window as any).ym as ym.Event
    ym(id, 'init', { ...options, defer: true })
    ym(id, 'hit', window.location.href)

    const handler = (url: string, { shallow }: { shallow: boolean }) =>
      ym(id, 'hit', window.location.href)

    router.events.on('routeChangeComplete', handler)

    return () => router.events.off('routeChangeComplete', handler)
  }, [id, options, router.events])

  return (
    <noscript>
      <div>
        <img
          src={`https://mc.yandex.ru/watch/${id}`}
          style={{ position: 'absolute', left: '-9999px' }}
          alt=""
        />
      </div>
    </noscript>
  )
}

export default YandexMetrica
