import { PaperAirplaneIcon, StopIcon } from '@heroicons/react/solid'
import { Popover, PopoverContent, PopoverPortal, PopoverTrigger } from '@radix-ui/react-popover'
import { useChat } from 'ai/react'
import { useToast } from 'components/app/NotificationArea'
import Card from 'components/base/Card'
import Tooltip from 'components/base/Tooltip'
import dayjs from 'dayjs'
import { AnimatePresence } from 'framer-motion'
import { FC, c } from 'lib/component-utils'
import { useUserContext } from 'lib/context'
import { useIntl } from 'lib/intl-utils'
import { KeyboardEvent, useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import TextareaAutosize from 'react-textarea-autosize'
import { storage } from 'lib/local-storage'
import { nanoid } from 'ai'
import { TrashIcon } from '@heroicons/react/outline'
import { renderToHtml } from 'lib/markdown'
import Markdown from 'components/base/Markdown'
import { AvailableModel } from 'lib/ai/openai'
import { match } from 'ts-pattern'
import Button from 'components/base/Button'
import InlineSelect from 'components/base/InlineSelect'

const SellmonitorGPT: FC = () => {
  const ctx = useUserContext()
  const { t, format } = useIntl()
  const [open, setOpen] = useState(false)
  const showToast = useToast()
  const formRef = useRef<HTMLFormElement | null>(null)
  const [renderedMessages, setRenderedMessages] = useState<string[]>([])
  const [model, setModel] = useState<AvailableModel>('gpt-4o-mini')
  const { messages, input, handleInputChange, handleSubmit, stop, isLoading, setMessages } =
    useChat({
      api: '/api/next/generate-chat-completion-stream/',
      credentials: 'include',
      headers: { 'x-auth': ctx.isAuthorized.toString() },
      initialMessages:
        typeof window === 'undefined'
          ? []
          : (() => {
              let history = storage.get('assistantHistory') ?? []
              history = history.filter(
                (message) => -dayjs(message.createdAt).diff(dayjs(), 'days') <= 3
              )
              storage.set('assistantHistory', history)
              return history
            })(),
      body: {
        mall: ctx.malls[0].code,
        m: match(model)
          .with('gpt-4o-mini', () => '45892c646be74ba48824abb61b30ed9d')
          .with('gpt-4o', () => '71ad9388a98b06f6d8fe6452184ebf1d')
          .exhaustive(),
      },
      onFinish: (message) => {
        const messages = storage.get('assistantHistory') ?? []
        messages.push(message)
        storage.set('assistantHistory', messages)
      },
      onResponse: (res) => {
        if (res.status === 429) {
          const reader = res.body?.getReader()
          const decoder = new TextDecoder()
          let result = ''

          if (reader) {
            const read = async (): Promise<number> => {
              const { done, value } = await reader.read()
              if (done) {
                const { seconds } = JSON.parse(result)
                return seconds
              }
              result += decoder.decode(value, { stream: true })
              return read()
            }
            read().then((seconds) => {
              const nextTryDate = format.date(dayjs().add(seconds, 'seconds').toISOString(), {
                day: 'numeric',
                month: 'long',
                hour: 'numeric',
                minute: 'numeric',
              })
              showToast(
                'info',
                t`ai.timeout.toast.title`,
                t(`ai.timeout.toast.message`, { date: nextTryDate })
              )
            })
          }
        }
      },
    })

  useEffect(() => {
    setModel(storage.get('assistantModel') ?? 'gpt-4o-mini')
  }, [])

  useEffect(() => {
    const renderMessages = async () => {
      const rendered = await Promise.all(messages.map((msg) => renderToHtml(msg.content)))

      setRenderedMessages(rendered)
    }

    renderMessages()
  }, [messages.length, messages.at(-1)?.content])

  const onEnterPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      formRef.current?.requestSubmit()
    }
  }

  return (
    <div className="fixed right-4 bottom-10 z-30">
      <Popover open={open} onOpenChange={setOpen}>
        <Tooltip content="SellmonitorGPT">
          <PopoverTrigger className="focus:outline-none group">
            <div className="flex relative justify-center items-center bg-white rounded-full">
              <div
                className={c`origin-center rounded-full group-hover:opacity-100 transition-opacity duration-500 w-14 h-14 group-hover:animate-spin-slow animate-spin-very-slow sellmonitor-gpt-shadow backdrop-blur ${open} opacity-100 | opacity-50 ${isLoading} !animate-spin`}
              />
            </div>
          </PopoverTrigger>
        </Tooltip>
        <AnimatePresence>
          {open && (
            <PopoverPortal forceMount>
              <PopoverContent
                forceMount
                className="z-50"
                side="top"
                align="end"
                sideOffset={16}
                collisionPadding={16}
              >
                <motion.div
                  initial={{ opacity: 0, scale: 0 }}
                  animate={{
                    opacity: 1,
                    scale: 1,
                    transition: { ease: 'easeOut' },
                  }}
                  exit={{
                    opacity: 0,
                    scale: 0,
                    transition: { ease: 'easeIn' },
                  }}
                  className="duration-100 origin-bottom-right"
                >
                  <Card className="h-[calc(100vh-130px)] bg-white z-[49] relative w-[65ch] !shadow-xl flex flex-col justify-between">
                    <div className="flex justify-between items-center pr-2 pl-4 w-full border-b border-gray-200">
                      {ctx.user.roles.includes('ROLE_ADMIN') && (
                        <InlineSelect
                          className="leading-4"
                          options={['gpt-4o-mini', 'gpt-4o'] as const}
                          display={(value: AvailableModel) =>
                            match(value)
                              .with('gpt-4o-mini', () => t`ai.fast`)
                              .with('gpt-4o', () => t`ai.smart`)
                              .exhaustive()
                          }
                          selected={model}
                          setSelected={setModel}
                        />
                      )}
                      <Tooltip content={t`ai.clear_history`}>
                        <Button
                          theme="gray"
                          className="ml-auto"
                          onClick={() => {
                            storage.set('assistantHistory', [])
                            setMessages([])
                            setRenderedMessages([])
                          }}
                        >
                          <TrashIcon className="text-gray-700 size-5" />
                        </Button>
                      </Tooltip>
                    </div>
                    <div className="flex overflow-y-auto overscroll-contain flex-col flex-col-reverse px-6 pt-8 pb-20">
                      <div className="space-y-4">
                        <div className="flex flex-col">
                          <span className="inline-flex items-center space-x-1.5">
                            <span className="font-medium">SellmonitorGPT</span>
                          </span>
                          <span className="whitespace-pre-line">{`Привет! 👋
                      Я SellmonitorGPT — умный ассистент Sellmonitor. Со мной можно говорить о чём угодно!
                      Пока что я мало знаю о специфике российских маркетплейсов и Sellmonitor, так что с конкретными вопросами и проблемами лучше пишите в поддержку. Но я учусь, и в будущем обязательно смогу рассказать вам и об этом. Следите за новостями!
                      А сейчас я помогу объяснить термины, придумать название или описание для товара, рассчитать какие-то показатели — только объясните мне, что нужно сделать, и я постараюсь помочь.`}</span>
                        </div>
                        {renderedMessages.length > 0 &&
                          renderedMessages.map((message, index) => {
                            return (
                              <div key={messages[index].id} className="flex flex-col">
                                <span className="inline-flex items-center space-x-1.5">
                                  <span className="font-medium">
                                    {messages[index]?.role === 'assistant'
                                      ? 'SellmonitorGPT'
                                      : 'Вы'}
                                  </span>
                                </span>
                                <Markdown
                                  content={message}
                                  className="space-y-2 [&_pre]:overflow-auto [&_code]:max-w-full [&_pre]:bg-gray-100 [&_pre]:w-[54ch] [&_pre]:p-2 [&_pre]:rounded-md"
                                />
                              </div>
                            )
                          })}
                      </div>
                    </div>
                    <form
                      ref={formRef}
                      onSubmit={(e) => {
                        if (input.length > 0) {
                          const messages = storage.get('assistantHistory') ?? []
                          messages.push({
                            id: nanoid(7),
                            role: 'user',
                            content: input,
                            createdAt: dayjs().toISOString() as unknown as Date,
                          })
                          storage.set('assistantHistory', messages)
                        }
                        handleSubmit(e)
                      }}
                      className="absolute inset-x-0 bottom-0 border-t border-gray-200"
                    >
                      <TextareaAutosize
                        rows={1}
                        maxRows={10}
                        value={input}
                        onChange={handleInputChange}
                        onKeyDown={onEnterPress}
                        className="w-full h-full py-4 -mb-1.5 resize-none pl-6 pr-[72px] text-sm border-none focus:border-none focus:ring-0"
                        placeholder="Спросите что угодно"
                      />
                      {!isLoading && (
                        <Tooltip content="Отправить сообщение">
                          <button className="absolute inset-y-0 right-4 w-[44px] flex items-center justify-center">
                            <PaperAirplaneIcon className="w-6 h-6 text-blue-600 rotate-90" />
                          </button>
                        </Tooltip>
                      )}
                      {isLoading && (
                        <Tooltip content="Остановить ответ">
                          <button
                            type="button"
                            onClick={stop}
                            className="absolute inset-y-0 right-4 w-[44px] flex items-center justify-center"
                          >
                            <StopIcon className="w-7 h-7 text-blue-600" />
                          </button>
                        </Tooltip>
                      )}
                    </form>
                  </Card>
                </motion.div>
              </PopoverContent>
            </PopoverPortal>
          )}
        </AnimatePresence>
      </Popover>
    </div>
  )
}

export default SellmonitorGPT
