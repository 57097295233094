import { FunctionComponent as ReactFunctionComponent } from 'react'

export const c = (
  strings: TemplateStringsArray,
  ...params: (boolean | string | undefined | null)[]
) => {
  let classnames: string[] = []
  classnames.push(strings[0])
  for (let i = 0; i < params.length; i++) {
    const command = params[i]
    const arg = strings[i + 1]
    if (typeof command === 'boolean') {
      const branches = arg.split('|')
      const chosenBranch = command ? branches[0] : branches[1]
      classnames.push(chosenBranch)
    } else if (typeof command === 'string') {
      classnames.push(command, arg)
    } else {
      classnames.push(arg)
    }
  }

  return classnames
    .filter(Boolean)
    .map((s) => s.trim())
    .filter(Boolean)
    .join(' ')
}

export type Element = keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>

export type PropsOf<E extends Element> = JSX.LibraryManagedAttributes<
  E,
  React.ComponentPropsWithRef<E>
>

export type CombinedProps<P, E extends Element | null> = E extends Element
  ? Omit<Partial<PropsOf<E>>, keyof P> & P
  : P

export type FunctionComponent<P = {}, E extends Element | null = 'div'> = ReactFunctionComponent<
  CombinedProps<P, E>
>

export type FC<P = {}, E extends Element | null = 'div'> = FunctionComponent<P, E>

export type AsProp<C extends React.ElementType> = {
  as?: C
}

export type PropsToOmit<C extends React.ElementType, P> = keyof (AsProp<C> & P)

export type PolymorphicComponentProps<
  C extends React.ElementType,
  Props = {}
> = React.PropsWithChildren<Props & AsProp<C>> &
  Omit<React.ComponentPropsWithoutRef<C>, PropsToOmit<C, Props>>

export type PolymorphicComponentPropsWithRef<
  C extends React.ElementType,
  Props = {}
> = PolymorphicComponentProps<C, Props> & { ref?: PolymorphicRef<C> }

export type PolymorphicRef<C extends React.ElementType> = React.ComponentPropsWithRef<C>['ref']
