import { RadioGroup } from '@headlessui/react'
import { c } from 'lib/component-utils'
import { DisplayExtractor, extract, KeyExtractor } from 'lib/type-utils'
import { Fragment } from 'react'

type Props<T> = {
  options: T[]
  selected: T
  setSelected: (value: T) => void
  disabled?: T[]
  onDisabledClick?: (value: T) => void
  optionKey?: KeyExtractor<T>
  display?: DisplayExtractor<T>
  className?: string
  compareBy?: (keyof T & string) | ((a: T, z: T) => boolean)
}

const InlineSelect = <T,>({
  options,
  selected,
  setSelected,
  disabled,
  onDisabledClick,
  optionKey,
  display,
  compareBy,
  className,
}: Props<T>) => {
  return (
    <RadioGroup
      by={compareBy}
      value={selected}
      onChange={setSelected}
      className={c`inline-flex items-center font-medium bg-gray-200/60 border border-gray-200/60 rounded-lg p-[2px] space-x-[2px] ${className}`}
    >
      {options.map((option) => (
        <RadioGroup.Option as={Fragment} value={option} key={extract(option, optionKey)}>
          {({ checked }) => (
            <span
              onClick={(event) => {
                if (!disabled?.includes(option)) {
                  return
                }
                event.preventDefault()
                onDisabledClick?.(option)
              }}
              className={c`hide-separators outline-none focus-visible:ring-2 focus-visible:ring-primary-500 relative first-of-type:before:hidden w-max transition-colors duration-300 py-0.5 px-3 hover:bg-gray-50 block cursor-pointer rounded-md before:absolute before:top-[calc((2*2px+7px)/2)] before:left-0 before:-translate-x-px before:w-px before:h-[calc(100%-2*2px-7px)] before:bg-gray-300 ${checked} text-black bg-white shadow-sm hover:bg-white | text-gray-500`}
            >
              {extract(option, display)}
            </span>
          )}
        </RadioGroup.Option>
      ))}
    </RadioGroup>
  )
}

export default InlineSelect
