import PubSub from 'pubsub-js'
import { useEffect } from 'react'
import { MercureMessage } from './dto'

export const useMercureEvent = <T extends MercureMessage>(
  topic: T['event'],
  handler: (message: T['event'], data: T['data']) => void
) => {
  useEffect(() => {
    const token = PubSub.subscribe(topic, handler)

    return () => {
      PubSub.unsubscribe(token)
    }
  }, [])
}
