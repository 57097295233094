import Link from 'components/base/Link'
import { AnimatePresence, motion } from 'framer-motion'
import { c, FC } from 'lib/component-utils'
import { useUserContext } from 'lib/context'
import { useIntl } from 'lib/intl-utils'
import { useRouter } from 'next/router'
import { useCallback, useEffect } from 'react'
import { findActiveNavItem } from 'components/sidebar/lib/navItems'
import Tooltip from 'components/base/Tooltip'

type Props = {
  open: boolean
  setOpen: (value: boolean) => void
}

const Malls: FC<Props> = ({ open, setOpen }) => {
  const ctx = useUserContext()
  const { user, malls, theme, currentMall } = ctx
  const { t, format } = useIntl()
  const router = useRouter()

  const activeNavItem = findActiveNavItem(router)

  const close = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const listener = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      close()
    }
  }

  useEffect(() => {
    if (open) {
      addEventListener('keydown', listener)
      return () => removeEventListener('keydown', listener)
    }
  }, [open])

  useEffect(() => {
    if (open) close()
  }, [router.asPath])

  return (
    <AnimatePresence>
      {open && (
        <motion.div
          id="mall-container"
          className="left-[calc(3rem+1px)] flex flex-col items-start large-sidebar:left-[calc(14rem+1px)] z-[1000] fixed inset-0 h-screen max-h-screen overflow-auto bg-white"
          initial={{ opacity: 0, translateX: -48 }}
          animate={{ opacity: 1, translateX: 0 }}
          exit={{ opacity: 0, translateX: -48 }}
          transition={{ ease: 'easeInOut', duration: 0.3 }}
        >
          <div
            className={c`min-w-[calc(70rem-3rem-1px)] w-full large-sidebar:min-w-[calc(70rem-14rem-1px)] grid grid-flow-col-dense auto-cols-[minmax(170px,200px)] gap-6 p-12 grid-rows-5 2xl:grid-rows-6`}
          >
            {malls.map((mall) => {
              return (
                <div key={mall.code} className="h-[13vh] max-h-[8rem]">
                  <Link
                    onClick={() => setOpen(false)}
                    href={
                      activeNavItem &&
                      (activeNavItem.customVisibilityCondition?.(ctx) ||
                        (!activeNavItem.customVisibilityCondition &&
                          user.roles.includes(activeNavItem.role) &&
                          (!activeNavItem.malls || activeNavItem.malls.includes(mall.code)) &&
                          (!activeNavItem.hosts ||
                            activeNavItem.hosts.includes(theme.hostType)))) &&
                      activeNavItem.addMall
                        ? `${activeNavItem.route}${mall.code}/`
                        : `/products/list/${mall.code}/`
                    }
                  >
                    <div
                      className={c`flex relative flex-col justify-center items-center h-full rounded-lg ring-1 ring-gray-100 shadow-sm transition duration-300 bg-gray-100/70 hover:bg-gray-200/50 hover:ring-gray-200 hover:duration-150 ${
                        mall.code === currentMall.code
                      } ring-2 ring-offset-4 ring-gray-500 hover:ring-gray-600`}
                    >
                      {mall.stat.productsCount !== null && (
                        <Tooltip content={t`products`}>
                          <div className="w-max top-[-1px] right-[-1px] pt-1 pr-1.5 border-b border-l border-gray-200/40 font-medium bg-gray-600/5 pb-0.5 px-1 absolute text-[10px] text-gray-500 rounded-tr-lg rounded-bl-lg">
                            <span>{format.number(mall.stat.productsCount)}</span>
                          </div>
                        </Tooltip>
                      )}
                      {mall.stat.productsCount === null && (
                        <div className="w-max top-[-1px] right-[-1px] pt-1 pr-1.5 border-b border-l border-red-200/40 font-medium bg-red-600/5 pb-0.5 px-1 absolute text-[10px] text-red-500 rounded-tr-lg rounded-bl-lg">
                          {t`not working`}
                        </div>
                      )}
                      <img
                        src={`/img/mall_ico/${mall.code}_big.png`}
                        className="w-36 top-1.5 relative object-contain"
                        alt={`${mall.description} logo`}
                      />
                    </div>
                  </Link>
                </div>
              )
            })}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default Malls
