import axios from 'axios'
import { HostType } from 'configs/theme'
import { useUserContext } from 'lib/context'
import { ActionpayAction, actionpayGoalIds } from 'lib/dto'

export const useYandexMetrica = () => {
  const {
    theme: { yandexMetrica },
  } = useUserContext()
  const ym =
    process.env.NODE_ENV === 'production' ? () => (window as any).ym as ym.Event : () => () => {}
  return [yandexMetrica?.id, ym] as const
}

type Goal =
  | `registration-${'filled-all-fields' | 'finished'}`
  | `moved-to-${'messenger' | 'youtube' | 'telegram-bot'}`
  | `landing-${
      | 'watched-video'
      | 'clicked-register'
      | 'clicked-register-header'
      | `clicked-register-${'wb' | 'ozon' | 'beru'}`
      | `use-cases-clicked-${2 | 3}`
      | `features-clicked-${2 | 3 | 4}`}`
  | `subscription-${'picked-retailer' | 'started-adding-card' | 'added-card' | 'paid'}`

export const useTrackGoal = () => {
  const [ymId, yandexMetrica] = useYandexMetrica()
  return (goalName: Goal) => {
    if (!ymId) return
    yandexMetrica()(ymId, 'reachGoal', goalName)
  }
}

export const trackActionpayActionServer = async (
  action: (typeof actionpayGoalIds)[ActionpayAction],
  actionId: string,
  price: number,
  cookies: Record<string, string>
) => {
  const actionpayCookie = cookies['actionpay']
  const utmSource = cookies['utm_source']

  if (utmSource !== 'actionpay' || process.env.NODE_ENV !== 'production') return

  return axios.get(
    `https://x.actionpay.ru/ok/${action}.png?actionpay=${actionpayCookie}&apid=${actionId}&price=${price}`
  )
}

type PrmEventType = 'FOLLOW'

export const trackPrmEvent = async (
  eventType: PrmEventType,
  entityIdent: string,
  leadIdent: string,
  hostType: HostType
) => {
  if (process.env.NODE_ENV !== 'production') return

  const username =
    hostType === 'sellmonitor'
      ? process.env.PRM_ONLINE_SELLMONITOR_LOGIN!
      : process.env.PRM_ONLINE_SELLSCREEN_LOGIN!

  const password =
    hostType === 'sellmonitor'
      ? process.env.PRM_ONLINE_SELLMONITOR_PASS!
      : process.env.PRM_ONLINE_SELLSCREEN_PASS!

  return axios.post(
    `http://212.8.238.177:9877/interfaces_prmsaas/resources/events`,
    {
      eventType,
      entityIdent,
      leadIdent,
    },
    { auth: { username, password } }
  )
}
