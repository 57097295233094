import { useAtomValue, useSetAtom } from 'jotai'
import { sidebarIsLargeAtom, windowDimensionsAtom } from 'components/sidebar/lib/atoms'
import { FC } from 'lib/component-utils'
import debounce from 'lodash/debounce'
import { useEffect, useState } from 'react'
import Malls from './Malls'
import Sidebar from './Sidebar'

const SidebarContainer: FC = () => {
  const [mallSelectorOpen, setMallSelectorOpen] = useState(false)
  const sidebarIsLarge = useAtomValue(sidebarIsLargeAtom)
  const updateWindowDimensions = useSetAtom(windowDimensionsAtom)

  useEffect(() => {
    const resizeHandler = debounce(() => {
      updateWindowDimensions([window.innerWidth, window.innerHeight])
    }, 100)

    resizeHandler()
    window.addEventListener('resize', resizeHandler)

    return () => window.removeEventListener('resize', resizeHandler)
  }, [])

  useEffect(() => {
    const html = document.documentElement
    if (sidebarIsLarge) {
      html.classList.add('large-sidebar')
    } else {
      html.classList.remove('large-sidebar')
    }
  }, [sidebarIsLarge])

  return (
    <div>
      {sidebarIsLarge !== null && (
        <>
          <Malls open={mallSelectorOpen} setOpen={setMallSelectorOpen} />
          <Sidebar setMallsOpen={setMallSelectorOpen} />
        </>
      )}
    </div>
  )
}

export default SidebarContainer
